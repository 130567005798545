/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// CSS
import 'styles/Contact.scss'

// Images
import facebookIcon from 'img/facebook.svg'
import instagramIcon from 'img/instagram.svg'
import linkedinIcon from 'img/linkedin.svg'
import youtubeIcon from 'img/youtube.svg'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Banner from 'components/Banner'
import Content from 'components/Content'
import GravityForm from 'components/GravityForm'
import { getLanguage } from 'services/language'

// Third Party
import styled from 'styled-components'

const BannerContainer = styled.div``

function Contact({ data, pageContext }) {
  const {
    wordpressPage: {
      title,
      acf: { banner, left, right },
    },
  } = data

  return (
    <Layout>
      <SEO title={title} />
      <BannerContainer>
        <Banner image={banner.image} title={title} />
      </BannerContainer>
      <section className="container my-3 mb-sm-3 mb-lg-5">
        <div className="row">
          <div className="col">
            <Breadcrumb
              crumbSeparator=" > "
              crumbs={pageContext.breadcrumb.crumbs}
              crumbLabel={title}
            />
          </div>
        </div>
      </section>

      <section className="container contact">
        <div className="row">
          <div className="col-lg-6">
            <Content content={left.content} />

            <div className="col-lg-10 px-0">
              <GravityForm id={getLanguage() === 'nl_NL' ? 1 : 2} />
            </div>
          </div>

          {title !== 'Kerst event 2023' && (
            <div className="col-lg-6 pl-lg-5 mb-5">
              <div className="row">
                <div className="col-sm-6">
                  <p className="mb-2 font-weight-xl color-main">
                    {right.vestigingen_title}
                  </p>
                  {right.vestigingen.map((vestigingen, key) => (
                    <React.Fragment key={key}>
                      <p className="color-main mb-0 font-weight-bold">
                        {vestigingen.title}
                      </p>
                      <p className="mb-0">{vestigingen.adres}</p>
                      <p className="">{vestigingen.postcode_en_plaats}</p>
                    </React.Fragment>
                  ))}
                </div>

                <div className="col-sm-6">
                  <p className="mb-2 font-weight-xl color-main">
                    {right.contact_title}
                  </p>

                  <Content
                    className="contact-info"
                    content={right.contact_info}
                  />

                  <div className="social-icons mb-4">
                    <a
                      className="pr-2 pl-0"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://facebook.com/BrandwiseGroupBV"
                    >
                      <img
                        className="social-icon"
                        alt="Facebook"
                        src={facebookIcon}
                      />
                    </a>

                    <a
                      className="px-2"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/brandwisegroup/"
                    >
                      <img
                        className="social-icon"
                        alt="Instagram"
                        src={instagramIcon}
                      />
                    </a>

                    <a
                      className="px-2"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.linkedin.com/company/brandwise-group-b-v-/"
                    >
                      <img
                        className="social-icon"
                        alt="LinkedIn"
                        src={linkedinIcon}
                      />
                    </a>

                    <a
                      className="px-2"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.youtube.com/channel/UCn4sOR3ke88QF5XXPL9J6Ww"
                    >
                      <img
                        className="social-icon"
                        alt="Youtube"
                        src={youtubeIcon}
                      />
                    </a>
                  </div>

                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    className="button button-alt px-4 font-size-ms"
                    href="https://maps.google.com/maps?ll=52.033155,5.609928&z=17&t=m&hl=nl&gl=US&mapclient=embed&daddr=Bonnetstraat%2037%206718%20XN%20Ede@52.03315509999999,5.6099283"
                  >
                    {right.route_button}
                  </a>
                </div>

                <div className="col-12 mt-3">
                  <iframe
                    title="Routebeschrijving"
                    width="100%"
                    height="325"
                    src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=nl&amp;q=Darwinstraat%2031%2C%206718%20XR%20Ede+(Brandwise)&amp;ie=UTF8&amp;t=&amp;z=17&amp;iwloc=B&amp;output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query ContactQuery($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      id
      wordpress_id
      title
      slug
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }

        left {
          content
        }

        right {
          vestigingen_title
          vestigingen {
            title
            adres
            postcode_en_plaats
          }
          contact_title
          contact_info
          route_button
        }
      }
    }
  }
`
